import React, { useState } from 'react';
import { IFieldProps } from '../../Field';
import { DateInput, DateTimeInput } from '../../types/date';


import './DateTimeField.scss';
interface Props extends IFieldProps {
    id: string;
    label?: string;
}
export const DateTimeField: React.FC<Props> = ({id, initObj={}, field, label, changeHandler}) => {

 return (
    <div className="field date-field">
        <label htmlFor={id}>{label || field.label || field.name} {field.required && <small style={{color: 'red'}}>*</small>}</label>
        <DateTimeInput init={initObj[field.name]} id={id} name={field.name} onChange={changeHandler} />
    </div>
 );
}

export const DateField: React.FC<Props> = ({id, initObj={}, field, label, changeHandler}) => {

    return (
       <div className="field date-field">
           <label htmlFor={id}>{label || field.label || field.name} {field.required && <small style={{color: 'red'}}>*</small>}</label>
           <DateInput init={initObj[field.name]} id={id} name={field.name} onChange={changeHandler} />
       </div>
    );
   }