import { useEffect, useState } from "react";

interface DropdownParameters {
    ref: any;
    onSelected: (v: any) => void;
    list: any[];
     dKey: string; 
     vKey: string;
     shouldSelectValue?: boolean;
     preselect?: boolean;
}
type tDropdown = (
    v: DropdownParameters) => {
    onSelectClicked: (e: any) => void;
    selectedItem: any;
    onItemClicked: (e: any) => void;
}


export const useDropdownFuncs: tDropdown = ({ref, onSelected, list, dKey, vKey, shouldSelectValue = true, preselect = true}) => {
    const [selectedItem, setSelectedItem] = useState(list[0] || {});
    
    const onItemClicked = (e: any) => {
        const dropdown = ref.current;
        const selected = dropdown?.querySelector('.selected');
        const select = dropdown?.querySelector('.select');
        const caret = dropdown?.querySelector('.caret');
        const lists = dropdown?.querySelector('.lists');
        const options = dropdown?.querySelectorAll('.lists li');
        const currentElement = e.target;
        const text = currentElement.innerText;
        (selected as any).innerText = text;

        select?.classList.remove('select-clicked');
        caret?.classList.remove('caret-rotate');
        lists?.classList.remove('lists-open');

        options?.forEach((opt:any) => opt.classList.remove('active'))
        currentElement.classList.add('active');
        // after ui changes are made 
        // send value to form
        const item = list.find(l => (l[dKey] as any).toLowerCase() === text.toLowerCase());
        onSelected(shouldSelectValue ? (item as any)[vKey] : item);
    }

    const onSelectClicked = (e: any) => {
        // const select = e.target;// target could be anything within the select div
        const select = ref.current.querySelector('.select');
        setSelectUIValue(select);
    }
    // const outsideClick = (bool: boolean) => {
    //     const dropdown = ref.current;
    //     const select = dropdown?.querySelector('.select');
    //     setSelectUIValue(select);
    // }

    const setSelectUIValue = (select: HTMLElement) => {
        const caret = select?.querySelector('.caret');
        const lists = select?.nextSibling;
        select?.classList.toggle('select-clicked');
        caret?.classList.toggle('caret-rotate');
        (lists as any).classList.toggle('lists-open');
    }

    useEffect(() => {
        if(preselect) {
            // list starts out empty
            const firstItem = list[0];
            setSelectedItem(firstItem || {});
            if(!!firstItem) onSelected(shouldSelectValue ? firstItem[vKey] : firstItem);
        }
        return () => {}
    }, [list])

    return { onSelectClicked, selectedItem, onItemClicked}
}
