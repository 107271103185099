import { useState } from "react";
import { IInput } from "../../Field";

interface Props extends IInput {

}
export const PhoneInput: React.FC<Props> = ({id, init, name, onChange}) => {
    const [value, setValue] = useState(init || '');
    
    const handleChange = (e: any) => {
        const x = e.target.value;
        const v = x.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        setValue(v.phone());
        onChange({name: name, value: v});
    }


    return (
        <input id={id} value={value} name={name} onChange={handleChange} />
    );
}